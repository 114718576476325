let timestamp = Date.now();

export default {
  /**
   * This method gets the current timestamp (in milliseconds), if there is a query
   * parameter added to the current URL with the key `timestamp` it will find and
   * use that instead.
   *
   * @returns timestamp
   */
  get() {
    const regex = new RegExp(`[?&]${encodeURIComponent('timestamp')}=([^&]*)`);
    const nameSearch = regex.exec(window.location.search);
    if (nameSearch) {
      timestamp = decodeURIComponent(nameSearch[1]);
    }
    return timestamp;
  },
};
